import Vue from 'vue'
import get from 'lodash/get'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'

export const state = () => ({
  selectedPayment: {},
  newCurrency: null,
  newTotalPrice: null,
  discountData: {},
  promoCode: '',
  prevBookStatus: null,
  bookData: null,
  agreePriceChanged: 0,
  bookGetApiCallTimes: 0,
  bookStatusApiCallTimes: 0,
  bookAble: ['bookdone', 'waiting', 'pricechanged', 'waitingchanged'],
  bookInProcess: [
    'none',
    'processing',
    'reprocessing',
    'queueing',
    'requeueing'
  ],
  paymentSubmittAble: ['bookdone', 'waiting'],
  priceChangedStatuses: ['pricechanged', 'waitingchanged'],
  paymentProcessingStatuses: ['processing', 'waiting'],
  isGetBookDataFailed: false,
  isUpdatePrice: false,
  bankOptionsData: null,
  bankOptionsMeta: {},
  isPaymentSelected: false,
  bookStatus: null,
  isFetchingBookData: false,
  xpayAmount: null,
  agentBalance: null,
  isSubmitPaymentOnProcess: null
})

export const mutations = {
  SET_SELECTED_PAYMENT(state, payload) {
    state.selectedPayment = payload
  },

  SET_NEW_CURRENCY(state, params) {
    state.newCurrency = params
  },

  SET_NEW_TOTAL_PRICE(state, params) {
    state.newTotalPrice = params
  },

  SET_DISCOUNT_DATA(state, params) {
    state.discountData = { ...state.discountData, ...params }
  },

  SET_BANK_OPTIONS_DATA(state, payload) {
    state.bankOptionsData = payload
  },

  SET_BANK_OPTIONS_META(state, payload) {
    state.bankOptionsMeta = payload
  },

  SET_PROMO_CODE(state, params) {
    state.promoCode = params
  },

  SET_PREV_BOOK_STATUS(state, newBookStatus) {
    state.prevBookStatus = newBookStatus
  },

  SET_BOOK_DATA(state, newBookData) {
    if (!state.bookData) {
      Vue.set(state, 'bookData', newBookData)
    } else {
      Object.keys(newBookData).forEach(key => {
        Vue.set(state.bookData, key, newBookData[key])
      })
    }
  },

  SET_BOOK_STATUS(state, payload) {
    Vue.set(state, 'bookStatus', payload)
  },

  SET_AGREE_PRICE_CHANGED(state, value) {
    state.agreePriceChanged = value
  },

  SET_GET_BOOK_FAILED(state, payload) {
    state.isGetBookDataFailed = payload
  },

  SET_UPDATE_PRICE(state) {
    state.isUpdatePrice = !state.isUpdatePrice
  },

  SET_IS_PAYMENT_SELECTED(state, payload) {
    state.isPaymentSelected = payload
  },

  RESET_AGREE_PRICE_CHANGED(state) {
    state.agreePriceChanged = 0
  },

  RESET_PROMO_CODE(state) {
    state.promoCode = ''
  },

  RESET_DISCOUNT_DATA(state) {
    state.discountData = {}
  },

  RESET_NEW_CURRENCY(state) {
    state.newCurrency = null
  },

  RESET_BOOK_DATA(state) {
    state.selectedPayment = {}
    state.newCurrency = null
    state.newTotalPrice = null
    state.discountData = {}
    state.promoCode = ''
    state.prevBookStatus = null
    state.bookData = null
    state.bookStatus = null
    state.agreePriceChanged = 0
    state.bookGetApiCallTimes = 0
    state.bookStatusApiCallTimes = 0
  },

  RESET_SELECTED_PAYMENT(state) {
    state.selectedPayment = {}
  },
  RESET_BOOK_GET_CALL_TIMES(state) {
    state.bookGetApiCallTimes = 0
    state.bookStatusApiCallTimes = 0
  },
  SET_BOOK_DATA_FETCH_STATE(state, payload) {
    state.isFetchingBookData = payload
  },
  SET_XPAY_AMOUNT(state, payload) {
    state.xpayAmount = payload
  },

  SET_AGENT_BALANCE(state, payload) {
    state.agentBalance = payload
  },
  SET_SUBMIT_PAYMENT_ON_PROCESS(state, payload) {
    state.isSubmitPaymentOnProcess = payload
  }
}

export const getters = {
  getNewCurrency(state) {
    return state.newCurrency
  },
  getBankOptions(state) {
    return state.bankOptionsData
  },
  getNewTotalPrice(state) {
    return state.newTotalPrice
  },
  getisBookDataFailed(state) {
    return state.isGetBookDataFailed
  },
  getDiscountData(state) {
    return state.discountData
  },
  getPromoCode(state) {
    return state.promoCode
  },
  getPrevBookStatus(state) {
    return state.prevBookStatus
  },
  getBookData(state) {
    return state.bookData
  },
  getAgreeChange(state) {
    return state.agreePriceChanged
  },
  getSelectedPayment(state) {
    return state.selectedPayment
  },
  getBookAble(state) {
    return state.bookAble
  },
  getIsUpdatePrice(state) {
    return state.isUpdatePrice
  },
  getIsPaymentSelected(state) {
    return state.isPaymentSelected
  },
  getBookStatus(state) {
    return state.bookStatus
  },
  getLatestSubmittedPayment(state) {
    let lastPayment = null
    if (state.bookData && state.bookData.payments) {
      lastPayment = last(state.bookData.payments)
    }
    return lastPayment
  },
  getPayAbleState(state) {
    const result = {
      payAble: false,
      shouldAbortSubmit: false,
      hasActivePayment: false,
      hasPaymentDone: false,
      isSubmitPaymentOnProcess: state.isSubmitPaymentOnProcess,
      isPaymentSelected: state.isPaymentSelected,
      selectedPaymentMethod: get(
        state.selectedPayment,
        'payMethod',
        get(state.selectedPayment, 'method', null)
      )
    }
    const bookStatus = state.bookStatus.orderStatus.map(val => val.status)
    if (bookStatus.every(val => state.bookInProcess.includes(val))) {
      result.payAble = false
    } else if (
      bookStatus.every(val => state.paymentSubmittAble.includes(val))
    ) {
      result.payAble = true
    } else if (
      bookStatus.some(val => state.priceChangedStatuses.includes(val))
    ) {
      if (state.agreePriceChanged == 1) result.payAble = true
      else {
        result.payAble = false
        result.shouldAbortSubmit = true
      }
    }
    if (
      state.paymentProcessingStatuses.includes(
        state.bookData.status.paymentStatus
      )
    ) {
      result.payAble = false
      result.hasActivePayment = true
    } else if (state.bookData.status.paymentStatus == 'done') {
      result.payAble = false
      result.hasPaymentDone = true
    }
    return result
  },
  getActivePaymentData(state) {
    if (isEmpty(state.bookData)) return {}
    return last(state.bookData.payments)
  },
  isAfterSalesOrder(state) {
    return state.bookData && !!state.bookData.afsStatus
  }
}

export const actions = {
  async setSubmitPaymentOnProcess({ commit }, payload) {
    await commit('SET_SUBMIT_PAYMENT_ON_PROCESS', payload)
  },

  async getBook({ commit, state }, payload) {
    try {
      commit('SET_BOOK_DATA_FETCH_STATE', true)
      const responseGetBook = await this.$api.getBook({
        trxId: payload.trxId,
        email: payload.email
      })
      if (!responseGetBook) throw responseGetBook
      const { result, status_code, error } = responseGetBook
      if (!!result && status_code === 200 && !error) {
        commit('SET_BOOK_DATA_FETCH_STATE', false)
        commit('SET_GET_BOOK_FAILED', false)
        commit('SET_BOOK_DATA', result)
        commit('SET_BOOK_STATUS', result.status)
        return result
      } else throw error
    } catch (error) {
      commit('SET_BOOK_DATA_FETCH_STATE', false)
      commit('SET_GET_BOOK_FAILED', true)
      const ERROR_CODE = get(error, 'response.status', null)
      this.$sentry.configureScope(scope => {
        scope.setExtra('API_URL', 'getBook')
        scope.setExtra('LOG', 'store/payment')
        scope.setExtra('LOC', 'actions/getBook')
        scope.setTag('API_CALL_TIMES', state.bookGetApiCallTimes)
        this.$sentry.captureException(error)
      })
      if (ERROR_CODE === 401 || ERROR_CODE === 403 || ERROR_CODE === 404) {
        throw error
      }
    }
  },

  async getBookStatus({ commit, state, dispatch }, payload) {
    try {
      commit('SET_PREV_BOOK_STATUS', state.bookStatus)
      const responseBookStatus = await this.$api.getBookStatus({
        trxId: payload.trxId,
        email: payload.email
      })
      if (!responseBookStatus) throw responseBookStatus
      const { result, status_code, error } = responseBookStatus
      if (!!result && status_code == 200 && !error) {
        if (
          state.bookStatus &&
          JSON.stringify(state.bookStatus) !== JSON.stringify(result)
        ) {
          await dispatch('getBook', {
            trxId: payload.trxId,
            email: payload.email
          })
        }
        commit('SET_BOOK_STATUS', result)
      } else throw error
    } catch (error) {
      const ERROR_CODE = get(error, 'response.status', null)
      // this.$sentry.configureScope(scope => {
      //   scope.setExtra('API_URL', 'getBookStatus')
      //   scope.setExtra('LOG', 'store/payment')
      //   scope.setExtra('LOC', 'actions/getBookStatus')
      //   scope.setTag('API_CALL_TIMES', state.bookStatusApiCallTimes)
      //   this.$sentry.captureException(error)
      // })
      if (
        ERROR_CODE === 401 ||
        ERROR_CODE === 403 ||
        ERROR_CODE === 404 ||
        ERROR_CODE === 409
      ) {
        throw error
      }
    }
  },

  async getAgentBalance({ commit }) {
    try {
      const responseMemberBalance = await this.$api.getDepositBalance()
      if (!responseMemberBalance) throw responseMemberBalance
      const { result, status_code, error } = responseMemberBalance
      if (!!result && !error) {
        commit('SET_AGENT_BALANCE', result)
        return result
      } else throw error
    } catch (error) {
      const ERROR_CODE = get(error, 'response.status', null)
      if (
        ERROR_CODE === 401 ||
        ERROR_CODE === 403 ||
        ERROR_CODE === 404 ||
        ERROR_CODE === 409
      ) {
        throw error
      }
    }
  }
}
