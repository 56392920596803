import Vue from 'vue'

export const state = () => ({
  orders: {},
  deletedOrdersQueue: [],
  showStepper: false
})

export const mutations = {
  ADD_ORDER(state, payload) {
    Vue.set(state.orders, payload.trxId, payload)
  },

  ADD_DELETED_ORDERS(state, trxIds) {
    state.deletedOrdersQueue = state.deletedOrdersQueue.concat(trxIds)
  },

  DELETE_ORDER(state, trxId) {
    Vue.delete(state.orders, trxId)
  },

  SET_ORDERS(state, payload) {
    state.orders = Object.assign({}, payload)
  },

  SET_ORDER_STATUS(state, trxId) {
    if (state.orders[trxId]) Vue.set(state.orders[trxId], 'read', true)
  },

  SET_SHOW_STEPPER(state, payload) {
    state.showStepper = payload
  },

  RESET_ORDERS(state) {
    state.orders = {}
  },

  RESET_DELETED_ORDERS_QUEUE(state) {
    state.deletedOrdersQueue = []
  }
}

export const actions = {
  _normalizeOrdersData({ state, commit }) {
    /**
     * Change orders from array to object if the data from localStorage still array
     */
    if (Array.isArray(state.orders)) {
      const ordersObj = state.orders.reduce((obj, order) => {
        obj[order.trxId] = order
        return obj
      }, {})

      commit('SET_ORDERS', ordersObj)
    }
  },

  addOrder({ commit }, payload) {
    const data = {
      ...payload,
      read: false,
      expiryDate: new Date(payload.expiryDate).getTime(),
      timestamp: new Date().getTime()
    }

    commit('ADD_ORDER', data)
  },

  cleanOrders({ state, commit }) {
    for (const id of state.deletedOrdersQueue) {
      commit('DELETE_ORDER', id)
    }

    commit('RESET_DELETED_ORDERS_QUEUE')
  },

  updateReadStatuses({ commit }, orderIds) {
    for (const id of orderIds) {
      commit('SET_ORDER_STATUS', id)
    }
  }
}

export const getters = {
  getOrders(state) {
    const orders = Object.values(state.orders).slice()

    orders.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))

    return orders
  },

  getOrder: state => trxId => {
    return state.orders[trxId]
  },

  getUnreadOrders(_, { getOrders: orders }) {
    return orders.filter(order => !order.read)
  },

  getEmailRegister(_, { getOrders: orders }) {
    return orders.filter(order => !!order.hasEmailRegister)
  }
}
