import padStart from 'lodash/padStart'
import { isStringEqual } from '@/assets/js/globalFunction'
import currencyList from '@airpaz/apz-js/dist/master/en/currency-list'

const getSpReqArrivalOptions = () => {
  const arrivalOptions = []
  const getTimeString = n =>
    `${padStart(n + '', 2, '0')}.00 - ${padStart(n + 1 + '', 2, '0')}.00`

  for (let i = 0; i < 26; i++) {
    arrivalOptions.push(getTimeString(i < 24 ? i : i - 24))
  }

  return arrivalOptions
}

export const state = () => ({
  listLanguage: [
    { en: 'English', code: 'GB', key: 'en', hreflang: 'en', active: true },
    { id: 'Indonesia', code: 'ID', key: 'id', hreflang: 'id', active: true },
    { tl: 'Tagalog', code: 'PH', key: 'tl', hreflang: 'tl', active: true },
    {
      zh: '简体中文',
      code: 'CN',
      key: 'zh',
      hreflang: 'zh-Hans',
      active: true
    },
    {
      'zh-tw': '繁體中文',
      code: 'TW',
      key: 'zh-tw',
      hreflang: 'zh-Hant',
      active: true
    },
    { th: 'ภาษาไทย', code: 'TH', key: 'th', hreflang: 'th', active: true },
    { ja: '日本語', code: 'JP', key: 'ja', hreflang: 'ja', active: true },
    { ms: 'Malay', code: 'MY', key: 'ms', hreflang: 'ms', active: true },
    { ko: '한국어', code: 'KR', key: 'ko', hreflang: 'ko', active: true },
    { vi: 'Tiếng Việt', code: 'VI', key: 'vi', hreflang: 'vi', active: true },
    { hi: 'हिन्दी', code: 'IN', key: 'hi', hreflang: 'hi', active: true },
    { it: 'Italiano', code: 'IT', key: 'it', hreflang: 'it', active: true },
    { de: 'Deutsch', code: 'DE', key: 'de', hreflang: 'de', active: true },
    { es: 'Español', code: 'ES', key: 'es', hreflang: 'es', active: true },
    { fr: 'Français', code: 'FR', key: 'fr', hreflang: 'fr', active: true },
    { ru: 'Русский', code: 'RU', key: 'ru', hreflang: 'ru', active: true },
    { bn: 'বাংলা', code: 'BD', key: 'bn', hreflang: 'bn', active: true },
    { pt: 'Português', code: 'PT', key: 'pt', hreflang: 'pt', active: true },
    { km: 'Khmer', code: 'KH', key: 'km', hreflang: 'km', active: true },
    { nl: 'Nederlands', code: 'NL', key: 'nl', hreflang: 'nl', active: true },
    { pl: 'Polski', code: 'PL', key: 'pl', hreflang: 'pl', active: true },
    { tr: 'Türkçe', code: 'TR', key: 'tr', hreflang: 'tr', active: true },
    { sv: 'Svenska', code: 'SE', key: 'sv', hreflang: 'sv', active: true },
    { nb: 'Norsk', code: 'NO', key: 'nb', hreflang: 'nb', active: true },
    { ro: 'Română', code: 'RO', key: 'ro', hreflang: 'ro', active: true },
    { el: 'Ελληνικά', code: 'GR', key: 'el', hreflang: 'el', active: true },
    { da: 'Dansk', code: 'DK', key: 'da', hreflang: 'da', active: true },
    { uk: 'українська', code: 'UA', key: 'uk', hreflang: 'uk', active: true },
    { hr: 'Hrvatski', code: 'HR', key: 'hr', hreflang: 'hr', active: true },
    { hu: 'Magyar', code: 'HU', key: 'hu', hreflang: 'hu', active: true },
    { sk: 'Slovak', code: 'SK', key: 'sk', hreflang: 'sk', active: true },
    { fi: 'Suomi', code: 'FI', key: 'fi', hreflang: 'fi', active: true },
    { sl: 'Slovene', code: 'SI', key: 'sl', hreflang: 'sl', active: true },
    { sr: 'Srpski', code: 'RS', key: 'sr', hreflang: 'sr', active: true },
    {
      bg: 'български език',
      code: 'BG',
      key: 'bg',
      hreflang: 'bg',
      active: true
    }
  ],
  authenticationCurrency: [
    'hotel-search',
    'hotel-id',
    'flight-search',
    'flight-tickets-id'
  ],
  listCurrency: Object.values(currencyList),
  knownLocales: Object.freeze([
    'en',
    'id',
    'zh',
    'zh-tw',
    'ja',
    'tl',
    'th',
    'ms',
    'ko',
    'vi',
    'it',
    'de',
    'es',
    'fr',
    'hi',
    'ru',
    'bn',
    'pt',
    'km',
    'nl',
    'pl',
    'tr',
    'sv',
    'cs',
    'nb',
    'ro',
    'el',
    'da',
    'uk',
    'hr',
    'ca',
    'hu',
    'sk',
    'fi',
    'sl',
    'bg',
    'sr'
  ]),
  locale: 'en',
  currency: 'MYR',
  country: '',
  REDIRECTS: {
    DEFAULT: '/',
    HOTEL_BOOK_CACHE_NOT_FOUND: '/hotel',
    FLIGHT_BOOK_CACHE_NOT_FOUND: '/flight'
  },
  ip: '',
  illustration: Object.freeze({
    warning: 'warning.png',
    request: 'request.png',
    confirm: 'confirmation.png',
    cs: 'customer_service.png',
    '401': '401.png',
    '404': '404.png',
    '500': '500.png',
    noFlight: 'no_flight.png',
    noFound: 'no_found.png',
    noInternet: 'no_internet.png',
    noPromo: 'no_promo.png',
    noBook: 'no_recent_booking.png',
    paymentFailed: 'payment_failed.png',
    paymentSuccess: 'payment_success.png',
    paymentWait: 'waiting_payment.png',
    loginSuccess: 'success_login.png',
    submitSuccess: 'success_submit.png',
    success: 'success.png',
    error: 'error.png',
    cardSwipe: '14.png',
    dataSaved: '12.png',
    priceChange: 'price_change.png',
    expired: 'expired.png',
    confirmation: 'confirmation.png'
  }),
  hotelRequestArrivalOptions: Object.freeze(getSpReqArrivalOptions())
})

export const mutations = {
  async SET_LANG(state, locale) {
    if (state.knownLocales.indexOf(locale.toLowerCase()) !== -1) {
      state.locale = locale
    }
  },
  SET_COUNTRY(state, country) {
    state.country = country
  },
  SET_IP(state, ip) {
    state.ip = ip
  },
  SET_CURRENCY(state, curr) {
    state.currency = curr
  },
  SET_LIST_CURRENCY_LANG(state, locale) {
    const currencyList = require(`@airpaz/apz-js/dist/master/${locale.toLowerCase()}/currency-list`)
    state.listCurrency = Object.values(currencyList)
  }
}

export const getters = {
  getCurrency: state => curr => {
    return (
      state.listCurrency.find(({ currency }) =>
        isStringEqual(currency, curr)
      ) || {}
    )
  },
  getCurrencyConfig: state => {
    return (
      state.listCurrency.find(({ currency }) =>
        isStringEqual(currency, state.currency)
      ) || {}
    )
  },
  getLocale: state => () => {
    return state.locale
  },
  getLocaleName: (_, getters) => key => {
    const locales = getters.getListLanguageObj

    return (locales[key] && locales[key].str) || key
  },
  getCountry(state) {
    return state.country
  },
  activeLanguages: state => {
    const listLanguage = JSON.parse(JSON.stringify(state.listLanguage))

    return listLanguage.filter(
      l =>
        l.active ||
        process.env.APP_ENV === 'staging' ||
        process.env.APP_ENV === 'development'
    )
  },
  getListLanguageObj: (_, getters) => {
    const listLanguage = JSON.parse(JSON.stringify(getters.activeLanguages))

    return listLanguage.reduce((obj, lang) => {
      const key = Object.keys(lang)[0]
      obj[key] = { ...lang, str: lang[key] }

      return obj
    }, {})
  },
  getCountryFlag: state => code => {
    if (typeof code === 'undefined') code = state.locale

    const { code: flag = '' } =
      state.listLanguage.find(
        val => val.key.toLowerCase() === code.toLowerCase()
      ) || {}

    return flag.toLowerCase() === 'vi' ? 'vn' : flag.toLowerCase()
  }
}
