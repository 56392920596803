import Vue from 'vue'

export const state = () => ({
  selectedFlights: {},
  searchParams: null
})

export const mutations = {
  SET_SEARCH_PARAMS(state, params) {
    state.searchParams = params
  },
  SET_SELECTED_FLIGHTS(state, { key, flight }) {
    const newFlight = Object.assign({}, flight)

    if (newFlight.price) delete newFlight.price
    if (newFlight.priceBeforeDisc) delete newFlight.priceBeforeDisc

    Vue.set(state.selectedFlights, key, newFlight)
  },

  UPDATE_SELECTED_FLIGHTS(state, { key, data }) {
    Vue.set(state.selectedFlights, key, {
      ...state.selectedFlights[key],
      ...data
    })
  },

  RESET_SELECTED_FLIGHTS(state) {
    state.selectedFlights = {}
  }
}

export const actions = {
  commitFlights({ commit, rootState }) {
    const selectedFlights = rootState['flight-result'].selectedFlights
    const searchParams = {
      currency: rootState.config.currency,
      ...rootState['flight-result'].searchParams
    }

    commit('SET_SEARCH_PARAMS', searchParams)

    Object.entries(selectedFlights).forEach(([key, flight]) => {
      commit('SET_SELECTED_FLIGHTS', {
        key,
        flight
      })
    })
  }
}
