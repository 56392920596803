export const state = () => ({
  searchParamsHistory: {
    depDate: null,
    retDate: null,
    depAirport: null,
    arrAirport: null,
    passenger: {
      adult: 1,
      child: 0,
      infant: 0
    }
  },
  fareDepart: null,
  fareReturn: null
})

export const mutations = {
  SET_SEARCH_PARAMS_HISTORY(state, params) {
    state.searchParamsHistory = params
  },

  SET_FARE(state, params) {
    state.fareDepart = params.depart
    if (!!params.return) state.fareReturn = params.return
  }
}

export const actions = {
  _normalizeSearchHistoryData({ state, commit }) {
    const newSearchParamsHistory = {
      ...state.searchParamsHistory
    }

    if (
      state.searchParamsHistory.depAirport &&
      state.searchParamsHistory.depAirport.hasOwnProperty('airportCode')
    )
      newSearchParamsHistory.depAirport = null

    if (
      state.searchParamsHistory.arrAirport &&
      state.searchParamsHistory.arrAirport.hasOwnProperty('airportCode')
    )
      newSearchParamsHistory.arrAirport = null

    commit('SET_SEARCH_PARAMS_HISTORY', newSearchParamsHistory)
  }
}
