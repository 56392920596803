const SEARCH_HISTORY_LIMIT = 5

export const state = () => ({
  searchHistory: [],
  scrollPosition: ''
})

export const mutations = {
  ADD_SEARCH_HISTORY(state, searchParams) {
    const history = state.searchHistory.slice()

    history.unshift(searchParams)

    state.searchHistory = history.slice(0, SEARCH_HISTORY_LIMIT)
  },

  ADD_SCROLL_PREV_STATE(state, scrollPos) {
    state.scrollPosition = scrollPos
  }
}
