export const actions = {
  async nuxtServerInit({ commit }, { app, store }) {
    if (store.state.config.country) {
      try {
        const responseToggles = await app.$api.getToggleFeatures(
          store.state.config.country
        )

        if (responseToggles && responseToggles.result) {
          commit('toggle-features/SET_TOGGLE_FEATURES', responseToggles.result)
        }
      } catch (e) {}
    }
  }
}
