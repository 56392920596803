import { isEmpty, keyBy } from 'lodash'

export const state = () => ({
  airlineList: [],
  countryList: [],
  airportList: {},
  configAirport: [],
  popularCountry: [],
  topAirlineList: [],
  masterLocales: [
    'en',
    'hi',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'th',
    'tl',
    'vi',
    'zh',
    'zh-tw'
  ]
})

export const mutations = {
  INIT_COUNTRY_LIST(state, list) {
    state.countryList = Object.values(list)
  },
  INIT_AIRLINE_LIST(state, list) {
    state.airlineList = Object.values(list)
  },
  INIT_AIRPORT_LIST(state, list) {
    state.airportList = list
  },
  INIT_TOP_AIRLINE_LIST(state, list) {
    state.topAirlineList = list
  }
}

export const actions = {
  async fetchTopAirlineList({ state, commit, rootState }) {
    if (!isEmpty(state.topAirlineList)) return

    try {
      const {
        status_code,
        error,
        result
      } = await this.$api.getAirlineListByCountry(rootState.config.country)

      if (status_code === 200 && !error && Array.isArray(result))
        commit('INIT_TOP_AIRLINE_LIST', result.slice(0, 24))
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async fetchAirlineList({ state, commit }) {
    if (!isEmpty(state.airlineList)) return
    let locale = this.$i18n.locale
    try {
      if (!state.masterLocales.includes(locale)) {
        locale = 'en'
      }
      const module = await import(/* webpackChunkName: "master-[request]" */ `@airpaz/apz-js/dist/master/${locale}/airline-list`)
      const data = module.default || module

      commit(`INIT_AIRLINE_LIST`, data || [])
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async fetchCountryList({ state, commit }) {
    if (!isEmpty(state.countryList)) return
    let locale = this.$i18n.locale
    try {
      if (!state.masterLocales.includes(locale)) {
        locale = 'en'
      }
      const module = await import(/* webpackChunkName: "master-[request]" */ `@airpaz/apz-js/dist/master/${locale}/country-list`)
      const data = module.default || module

      commit(`INIT_COUNTRY_LIST`, data || [])
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },
  async fetchAirportList({ state, commit }, force) {
    if (!isEmpty(state.airportList) && !force) return

    let locale = this.$i18n.locale

    try {
      if (!state.masterLocales.includes(locale)) {
        locale = 'en'
      }

      const moduleAiport = await import(/* webpackChunkName: "master-[request]" */ `@airpaz/apz-js/dist/master/${locale}/airport-list`)
      const dataAirport = keyBy(moduleAiport.default || moduleAiport, 'code')
      const moduleMultiAiport = await import(/* webpackChunkName: "master-[request]" */ `@airpaz/apz-js/dist/master/${locale}/multi-airport-list`)
      const dataMultiAirport = moduleMultiAiport.default || moduleMultiAiport

      commit(`INIT_AIRPORT_LIST`, { ...dataAirport, ...dataMultiAirport })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}

export const getters = {
  getAirportDetail: state => code => {
    let city = {}

    if (!!code && typeof code === 'string') {
      city = state.airportList[code.toUpperCase()]
    }

    return city || {}
  },
  getAirportCityImage: (_state, getters) => (airportCode, cdnUrl) => {
    const airportDetail = getters.getAirportDetail(airportCode)
    if (isEmpty(airportDetail)) return ''
    const airportCityName = airportDetail.cityNameEN || airportDetail.cityName
    const imgCityName = airportCityName
      .split(' ')
      .join('-')
      .toLowerCase()
    return `${cdnUrl}/cities/320x180/${imgCityName}.png`
  },
  getAirlineDetail: state => code => {
    if (!code) return {}

    return (
      state.airlineList?.find(
        el => el?.id?.toLowerCase() === code.substr(0, 2).toLowerCase()
      ) || {}
    )
  },
  formattedAirportList: state => {
    const cl = state.airportList.slice()

    return cl.reduce((o, c) => ({ ...o, [c.code]: c }), {})
  },
  getCountryDetail: state => id => {
    if (!id || typeof id !== 'string') return {}

    const result =
      state.countryList.find(
        el => el.code.toLowerCase() === id.toLowerCase()
      ) || {}
    return result
  },
  formattedCountryList: state => {
    const cl = state.countryList.slice()

    return cl.reduce((o, c) => ({ ...o, [c.code]: c }), {})
  },
  getCountry: (_state, getters) => country => {
    const activeCountry = getters.formattedCountryList[country]

    return activeCountry || {}
  }
}
