export const state = () => ({
  orders: []
})

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },

  RESET_ORDERS(state) {
    state.orders = []
  }
}

export const actions = {
  setOrders({ commit }, orders) {
    commit('SET_ORDERS', orders)
  }
}
