import Vue from 'vue'

export const state = () => ({
  passengerIds: [],
  userSelectedOrderIds: [],
  statusMapTranslation: {
    on_progress: 'onprocess',
    need_approval: 'needapproval',
    on_review: 'onreview',
    need_payment: 'needpayment',
    refund_complete: 'refundcompleted',
    failed: 'rejected',
    on_request: 'statusonrequest',
    processed: 'inprocesswithairline',
    submitted_to_airline: 'statussubmittedtoairline',
    follow_up_to_airlines: 'followuptoairline'
  },
  afterSalesTypeMap: {
    refund: 'cancellation',
    addbaggage: 'add-baggage',
    changedata: 'change-passenger',
    changeflight: 'change-flight'
  },
  afsStatus: null,
  selectedFlights: {},
  afsPONameMap: {
    dob: 'birthdate',
    passport: 'passportnumber',
    passportexpdate: 'passportexpiry',
    idcardexpdate: 'idcardexpiry'
  },
  flightConfig: {},
  statusClassesMap: {
    on_progress: {
      textClass: 'status-on-progress',
      bgClass: 'status-on-progress-bg',
      statusText: 'requeststatusonprocess',
      statusIcon: 'clock'
    },
    on_review: {
      textClass: 'status-on-review',
      bgClass: 'status-on-review-bg',
      statusText: 'requeststatusonreview',
      statusIcon: 'clock'
    },
    need_approval: {
      textClass: 'status-need-approval',
      bgClass: 'status-need-approval-bg',
      statusText: 'requeststatuswaitapproval',
      statusIcon: 'clock'
    },
    need_payment: {
      textClass: 'status-need-payment',
      bgClass: 'status-need-payment-bg',
      statusText: 'requeststatuswaitpayment',
      statusIcon: 'clock'
    },
    completed: {
      textClass: 'status-completed',
      bgClass: 'status-completed-bg',
      statusText: 'requestprocessfinished',
      statusIcon: 'check'
    },
    cancelled: {
      textClass: 'status-cancelled',
      bgClass: 'status-cancelled-bg',
      statusText: 'requeststatuscancelled',
      statusIcon: 'times'
    },
    rejected: {
      textClass: 'status-rejected',
      bgClass: 'status-rejected-bg',
      statusText: 'requeststatusrejected',
      statusIcon: 'times'
    },
    expired: {
      textClass: 'status-cancelled',
      bgClass: 'status-cancelled-bg',
      statusText: 'requeststatusexpired',
      statusIcon: 'clock'
    },
    failed: {
      textClass: 'status-rejected',
      bgClass: 'status-rejected-bg',
      statusText: 'requeststatusrejected',
      statusIcon: 'times'
    },
    refund_complete: {
      textClass: 'status-completed',
      bgClass: 'status-completed-bg',
      statusText: 'requestprocessfinished',
      statusIcon: 'check'
    }
  }
})
export const getters = {
  getStatus: state => status => {
    return state.statusMapTranslation[status] || status
  },
  getAfsType: state => type => {
    return state.afterSalesTypeMap[type] || ''
  },
  getAfsStatus(state) {
    return state.afsStatus
  },
  getAfsPOName: state => key => {
    return state.afsPONameMap[key] || ''
  },
  getStatusClass: state => status => {
    //completed and refund complete is same
    return state.statusClassesMap[status] || status
  }
}
export const mutations = {
  SET_PASSENGER_IDS(state, payload) {
    state.passengerIds = payload
  },
  SET_SELECTED_ORDER_IDS(state, payload) {
    state.userSelectedOrderIds = payload
  },
  SET_AFS_STATUS(state, payload) {
    Vue.set(state, 'afsStatus', payload)
  },
  SET_FLIGHT_CONFIG(state, payload) {
    Vue.set(state, 'flightConfig', payload)
  },
  SET_SELECTED_FLIGHTS(state, { orderId, key, flight }) {
    const newFlight = Object.assign({}, flight)

    if (newFlight.priceBeforeDisc) delete newFlight.priceBeforeDisc

    Vue.set(state.selectedFlights, orderId, {
      ...state.selectedFlights[orderId],
      [key]: newFlight
    })
  },

  RESET_SELECTED_FLIGHTS(state) {
    state.selectedFlights = {}
  },

  RESET_SELECTED_ORDER_FLIGHTS(state, orderId) {
    Vue.set(state.selectedFlights, orderId, {})
  }
}

export const actions = {
  async setSelectedPassengerIds({ commit }, payload) {
    commit('SET_PASSENGER_IDS', payload)
  },
  async setSelectedOrderIds({ commit }, payload) {
    commit('SET_SELECTED_ORDER_IDS', payload)
  },
  async setFlightConfig({ commit }, payload) {
    commit('SET_FLIGHT_CONFIG', payload)
  },
  async getAfsDetail({ commit }, payload) {
    try {
      const responseGet = await this.$api.getAfterSalesDetail({
        trxId: payload.trxId,
        email: payload.email
      })
      if (!responseGet) throw responseGet
      const { result, status_code, error } = responseGet
      if (!!result && status_code === 200 && !error) {
        commit('SET_AFS_STATUS', result.status)
      } else throw error
    } catch (error) {
      const ERROR_CODE = get(error, 'response.status', null)
      if (ERROR_CODE !== 401) {
        this.$sentry.captureException(e)
      }
      this.handleApiError(e)
    }
  },
  commitFlights({ commit, rootState }, orderId) {
    const selectedFlights = rootState['flight-result'].selectedFlights

    Object.entries(selectedFlights).forEach(([key, flight]) => {
      commit('SET_SELECTED_FLIGHTS', {
        orderId,
        key,
        flight
      })
    })
  }
}
