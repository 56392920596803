import cloneDeep from 'lodash/cloneDeep'

const defaultValues = {
  title: null,
  subtitle: null,
  rightIcon: null,
  backHandler: null,
  hideOnScroll: false,
  customClass: ''
}

export const state = () => cloneDeep(defaultValues)

export const mutations = {
  SET_HEADER_CONFIG(state, header) {
    Object.assign(state, { ...state, ...header })
  },

  RESET_HEADER_CONFIG(state) {
    Object.assign(state, defaultValues)
  }
}

export const getters = {
  hasTitle(state) {
    return !!state.title
  },

  hasSubtitle(state) {
    return !!state.subtitle
  },

  hasRightIcon(state) {
    return !!state.rightIcon
  },

  hasBackHandler(state) {
    return !!state.backHandler
  }
}
