import Vue from 'vue'
import { pick, findIndex, omit } from 'lodash'
import { isStringEqual } from '@/assets/js/globalFunction'

export const state = () => ({
  passengerCount: {},
  contact: {},
  bookData: {},
  passenger: [],
  config: {},
  baggageOptions: {},
  selectedBaggage: {},
  bookHotelConfig: {
    hotel: {
      name: ''
    }
  },
  bookHotelContact: {
    title: '',
    firstName: '',
    lastName: '',
    mobile: '',
    mobileCode: '',
    email: '',
    unicodeName: '',
    address: '',
    country: '',
    city: '',
    state: '',
    zip: '',
    businessPhone: '',
    homePhone: '',
    passCountry: '',
    passport: ''
  },
  bookHotelGuest: {
    title: '',
    name: ''
  },
  bookHotelSSR: {},
  stepperType: '',

  // Saved anonymous contacts & travelers data
  anonContacts: [],
  anonTravelers: []
})
export const getters = {
  getStepperType(state) {
    return state.stepperType
  }
}
export const mutations = {
  SET_BOOK_DATA(state, payload) {
    state.bookData = payload
  },
  RESET_DATA(state) {
    state.passengerCount = {}
    state.passenger = []
    state.config = {}
    state.baggageOptions = {}
  },
  SET_CONTACT(state, payload) {
    state.contact = payload
  },
  SET_PASSENGER(state, payload) {
    state.passenger = payload
  },
  SET_BOOK_CONFIG(state, params) {
    state.config = { ...state.config, ...params }
  },
  SET_BOOK_HOTEL_CONFIG(state, params) {
    state.bookHotelConfig = { ...state.bookHotelConfig, ...params }
  },
  SET_BOOK_HOTEL_CONTACT(state, params) {
    state.bookHotelContact = { ...state.bookHotelContact, ...params }
  },
  SET_BOOK_HOTEL_GUEST(state, params) {
    state.bookHotelGuest = { ...state.bookHotelGuest, ...params }
  },
  SET_TYPE_STEPPER(state, params) {
    state.stepperType = params
  },
  UPDATE_SELECTED_BAGGAGE(state, payload) {
    state.selectedBaggage = payload
  },
  RESET_SELECTED_BAGGAGE(state) {
    state.selectedBaggage = {}
  },

  ADD_ANONYMOUS_CONTACT(state, contact) {
    state.anonContacts.push(contact)
  },
  ADD_ANONYMOUS_TRAVELER(state, traveler) {
    state.anonTravelers.push(traveler)
  },
  UPDATE_ANONYMOUS_CONTACT(state, { index, payload }) {
    Vue.set(state.anonContacts, index, payload)
  },
  UPDATE_ANONYMOUS_TRAVELER(state, { index, payload }) {
    Vue.set(state.anonTravelers, index, payload)
  }
}

export const actions = {
  async updateSelectedBaggage({ commit }, payload) {
    commit('UPDATE_SELECTED_BAGGAGE', payload)
  },
  async resetSelectedBaggage({ commit }) {
    commit('RESET_SELECTED_BAGGAGE')
  },

  updateAnonContacts({ state, commit }, contact = {}) {
    const contactProps = [
      'title',
      'firstName',
      'lastName',
      'mobileCode',
      'mobile',
      'country',
      'email'
    ]
    const newContact = pick(contact, contactProps)
    const existsContactIndex = findIndex(
      state.anonContacts,
      existingContact =>
        isStringEqual(existingContact.firstName, newContact.firstName) &&
        isStringEqual(existingContact.lastName, newContact.lastName)
    )

    if (existsContactIndex > -1)
      commit('UPDATE_ANONYMOUS_CONTACT', {
        index: existsContactIndex,
        payload: newContact
      })
    else commit('ADD_ANONYMOUS_CONTACT', newContact)
  },
  updateAnonTravelers({ state, commit }, travelers = []) {
    const travelerProps = [
      'title',
      'gender',
      'firstName',
      'lastName',
      'dob',
      'nationality',
      'idCard',
      'idCardCountry',
      'idCardExpDate',
      'passport',
      'passportCountry',
      'passportExpDate'
    ]

    travelers
      .map(traveler => pick(traveler, travelerProps))
      .forEach(newTraveler => {
        const existsTravelerIndex = findIndex(
          state.anonTravelers,
          existingTraveler =>
            isStringEqual(existingTraveler.firstName, newTraveler.firstName) &&
            isStringEqual(existingTraveler.lastName, newTraveler.lastName)
        )

        const prevTraveler = omit(
          state.anonTravelers[existsTravelerIndex],
          Object.keys(newTraveler)
        )

        const newTravelerPayload = {
          ...prevTraveler,
          ...newTraveler
        }

        if (existsTravelerIndex > -1)
          commit('UPDATE_ANONYMOUS_TRAVELER', {
            index: existsTravelerIndex,
            payload: newTravelerPayload
          })
        else commit('ADD_ANONYMOUS_TRAVELER', newTravelerPayload)
      })
  }
}
