import intersection from 'lodash/intersection'

export const state = () => ({
  selectedRooms: {},
  searchConfig: {}
})

export const mutations = {
  UPDATE_SELECTED_ROOMS(state, { id, room }) {
    const newSelectedRooms = Object.assign({}, state.selectedRooms)

    if (room.count === 0) delete newSelectedRooms[id]
    else newSelectedRooms[id] = room

    state.selectedRooms = Object.assign({}, newSelectedRooms)
  },

  RESET_SELECTED_ROOMS(state) {
    state.selectedRooms = {}
  },

  SET_SEARCH_CONFIG(state, params) {
    state.searchConfig = { ...state.searchConfig, ...params }
  }
}

export const getters = {
  getSearchConfig(state) {
    return function validateQuery(query) {
      const newQuery = { ...query }

      Object.keys(newQuery).forEach(
        key => !newQuery[key] && delete newQuery[key]
      )

      return { ...newQuery, ...state.searchConfig }
    }
  },

  getSelectedRoomsPaymentMethods(state) {
    return intersection(
      ...Object.values(state.selectedRooms)
        .map(room => (room.roomData && room.roomData.paymentMethods) || null)
        .filter(Boolean)
    )
  },

  getTotalSelectedRooms(state) {
    return Object.values(state.selectedRooms).reduce(
      (res, room) => (res += room.count),
      0
    )
  },

  getTotalRoomCapacity(state) {
    return Object.values(state.selectedRooms).reduce(
      (total, { count, roomData }) =>
        (total += count * (roomData.maxOccupancy || 0)),
      0
    )
  }
}
