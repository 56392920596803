export const state = () => ({
  data: null,
  version: null,
  locale: null
})

export const getters = {
  getTranslation: state => (type, text) => {
    return state.data[type][text]
  }
}

export const mutations = {
  updateTranslations(state, pars) {
    state.data = pars.data
    state.version = pars.version
    state.locale = pars.locale
  }
}
