import dayjs from 'dayjs'
import { findIndex, isEqual, pick } from 'lodash'

const SEARCH_HISTORY_LIMIT = 10

export const state = () => ({
  searchHistory: []
})

export const mutations = {
  ADD_SEARCH_HISTORY(state, searchParams) {
    state.searchHistory = state.searchHistory.filter(
      flight => !dayjs(flight.depDate).isBefore(dayjs(), 'date')
    )

    const propsToCheck = ['depAirport', 'arrAirport', 'depDate', 'retDate']
    const existingHistoryIndex = findIndex(state.searchHistory, history =>
      isEqual(pick(history, propsToCheck), pick(searchParams, propsToCheck))
    )

    if (existingHistoryIndex > -1) {
      state.searchHistory = state.searchHistory.filter(
        (_, index) => index !== existingHistoryIndex
      )
    }

    state.searchHistory.unshift({
      ...searchParams,
      // @TODO create expiry. how long?
      timestamp: dayjs().unix()
    })

    if (state.searchHistory.length > SEARCH_HISTORY_LIMIT) {
      state.searchHistory.pop()
    }
  }
}
