export const state = () => ({
  toggles: {}
})

export const mutations = {
  SET_TOGGLE_FEATURES(state, payload) {
    state.toggles = payload
  }
}

export const getters = {
  isToggleActive: state => toggleId => {
    return !!state.toggles[toggleId]
  }
}
