import Vue from 'vue'
import dayjs from 'dayjs'
import { pick, get, isEmpty, minBy } from 'lodash'

import { getFlightKey } from '@/assets/js/globalFunction'

import { EXPIRY_TIME } from '@/config/flight-defaults'

const getInitialState = () => ({
  reqIds: {},

  searchParams: null,

  /** Temporary storage for selected flights */
  selectedFlights: {},

  flightOneWay: {},
  flightRoundTrip: {}
})

export const state = getInitialState

export const mutations = {
  SET_FLIGHT_ONEWAY(state, { key, ...payload }) {
    Vue.set(state.flightOneWay, key, {
      ...state.flightOneWay[key],
      ...payload
    })
  },
  SET_FLIGHT_ROUNDTRIP(state, { key, ...payload }) {
    Vue.set(state.flightRoundTrip, key, {
      ...state.flightRoundTrip[key],
      ...payload
    })
  },
  SET_SELECTED_FLIGHTS(state, { key, flight }) {
    Vue.set(state.selectedFlights, key, flight)
  },
  SET_SEARCH_PARAMS(state, params) {
    state.searchParams = pick(params, [
      'depAirport',
      'arrAirport',
      'depDate',
      'retDate',
      'adult',
      'child',
      'infant'
    ])
  },

  REFRESH_FLIGHT_ONEWAY(state, { key, reqId }) {
    Vue.set(state.flightOneWay, key, {
      reqId,
      timestamp: dayjs().unix(),
      expiresAt: dayjs()
        .add(EXPIRY_TIME, 'ms')
        .unix(),
      flights: {}
    })
  },
  REFRESH_FLIGHT_ROUNDTRIP(state, { key, reqId }) {
    Vue.set(state.flightRoundTrip, key, {
      reqId,
      timestamp: dayjs().unix(),
      expiresAt: dayjs()
        .add(EXPIRY_TIME, 'ms')
        .unix(),
      flights: {}
    })
  },

  DELETE_FLIGHT_ONEWAY(state, key) {
    Vue.delete(state.flightOneWay, key)
  },
  DELETE_FLIGHT_ROUNDTRIP(state, key) {
    Vue.delete(state.flightRoundTrip, key)
  },

  RESET_SELECTED_FLIGHTS(state) {
    state.selectedFlights = {}
  },
  RESET(state) {
    Object.assign(state, getInitialState())
  }
}

export const actions = {
  updateFlightList(
    {
      state,
      commit,
      getters: { getRoundTripKey }
    },
    { key, lists, timestamp, expiresAt, isOneWay }
  ) {
    if (!isOneWay) key = getRoundTripKey(key)

    const commitKey = isOneWay ? 'SET_FLIGHT_ONEWAY' : 'SET_FLIGHT_ROUNDTRIP'
    const flights = get(
      isOneWay ? state.flightOneWay[key] : state.flightRoundTrip[key],
      'flights',
      {}
    )

    let tempList = Object.assign({}, flights)

    /** Prevent duplicate data or prioritize data for roundtrip */
    tempList = lists.reduce((newList, flight) => {
      const flightKey = getFlightKey(flight)

      if (!newList[flightKey] || /** Roundtrip */ !isOneWay)
        newList[flightKey] = { ...flight, isCombo: !isOneWay }

      return newList
    }, tempList)

    const payload = { key, flights: tempList }

    if (!!expiresAt) payload.expiresAt = expiresAt

    /** one-stop flight doesn't have timestamp
     * so either use livecrawl timestamp or default timestamp
     * generated during refreshReqIds if livecrawl flights is empty
     * */
    if (!!timestamp) payload.timestamp = timestamp

    commit(commitKey, payload)
  },

  resetFlightsByLivecrawlKeys(
    {
      state,
      commit,
      getters: { getRoundTripKey }
    },
    keys
  ) {
    keys.forEach(key => {
      const rtKey = getRoundTripKey(key)

      if (state.flightRoundTrip[rtKey]) commit('DELETE_FLIGHT_ROUNDTRIP', rtKey)
      else commit('DELETE_FLIGHT_ONEWAY', key)
    })
  },

  deleteReqId({ state, commit }, key) {
    if (!key || !(typeof key === 'string' || Array.isArray(key))) return

    const deleteFlight = key => {
      if (state.flightRoundTrip[key]) commit('DELETE_FLIGHT_ROUNDTRIP', key)
      else commit('DELETE_FLIGHT_ONEWAY', key)
    }

    if (typeof key === 'string') deleteFlight(key)
    else key.forEach(v => deleteFlight(v))
  },

  refreshReqIds(
    {
      commit,
      getters: { getRoundTripKey }
    },
    { isOneWay = true, ...keys }
  ) {
    const commitKey = isOneWay
      ? 'REFRESH_FLIGHT_ONEWAY'
      : 'REFRESH_FLIGHT_ROUNDTRIP'

    Object.entries(keys).forEach(([key, reqId]) => {
      if (!isOneWay) key = getRoundTripKey(key)

      commit(commitKey, { key, reqId })
    })
  }
}

export const getters = {
  getRoundTripKey: state => key => {
    const { depart: departFlight } = state.selectedFlights

    if (isEmpty(departFlight)) return key

    return key + '_' + getFlightKey(departFlight)
  },

  getListFlight: (state, { getRoundTripKey }) => key => {
    let flights = get(state.flightOneWay[key], 'flights', {})

    if (state.flightRoundTrip[getRoundTripKey(key)]) {
      const roundTripFlights = get(
        state.flightRoundTrip[getRoundTripKey(key)],
        'flights',
        {}
      )

      flights = { ...flights, ...roundTripFlights }
    }

    const flightListArr = Object.assign([], Object.values(flights))
    const cheapestFlight = minBy(flightListArr, 'total')

    return flightListArr.map(flight => ({
      ...flight,
      isCheapest: flight.total === cheapestFlight?.total
    }))
  },

  getExpiredFlights: (state, { getRoundTripKey }) => (
    key,
    isReturn = false,
    cb
  ) => {
    let flights = Object.assign({}, state.flightOneWay)

    if (isReturn) flights = { ...flights, ...state.flightRoundTrip }

    return Object.entries(flights)
      .filter(entry =>
        new RegExp(`${key}$|${getRoundTripKey(key)}$`, 'gi').test(entry[0])
      )
      .filter(entry => cb(entry[1].expiresAt))
      .map(entry => entry[0])
  },

  getReqIds: (state, { getRoundTripKey }) => (key, isOneWay = true) => {
    const flightMaster = isOneWay
      ? state.flightOneWay[key]
      : state.flightRoundTrip[getRoundTripKey(key)]

    return pick(flightMaster, ['reqId', 'timestamp', 'expiresAt'])
  }
}
