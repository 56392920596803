export const state = () => ({
  destinationLog: [],
  searchParamsHistory: {
    checkInDate: null,
    checkOutDate: null,
    guestCounter: {
      room: 1,
      adult: 2,
      childrenAge: []
    },
    destination: {}
  },
  searchedDestination: {},
  selectedHotel: null
})

export const mutations = {
  ADD_DESTINATION_LOG(state, destination) {
    const log = state.destinationLog.slice()
    log.unshift(destination)
    state.destinationLog = log.slice(0, 5)
  },

  SET_SEARCHED_DESTINATION(state, payload) {
    state.searchedDestination = Object.assign({}, payload)
  },

  SET_SEARCH_PARAMS_HISTORY(state, params) {
    state.searchParamsHistory = { ...state.searchParamsHistory, ...params }
  },

  SET_SELECTED_HOTEL(state, params) {
    state.selectedHotel = { ...state.selectedHotel, ...params }
  }
}
