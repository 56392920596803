import Vue from 'vue'
const defaultValues = {
  reasons: [
    {
      value: 1,
      text: 'cancelreason1'
    },
    {
      value: 2,
      text: 'cancelreason2'
    },
    {
      value: 3,
      text: 'cancelreason3'
    },
    {
      value: 4,
      text: 'cancelreason4'
    },
    {
      value: 5,
      text: 'cancelreason5'
    },
    {
      value: 6,
      text: 'cancelreason6'
    }
  ],
  bookData: null
}

export const state = () => defaultValues

export const mutations = {
  SET_BOOK_DATA(state, payload) {
    Vue.set(state, 'bookData', payload)
  }
}

export const getters = {}
