import { isEmpty } from 'lodash'

export const state = () => ({
  departFare: {},
  returnFare: {},
  holiday: []
})

export const mutations = {
  SET_FARE(state, params) {
    state.departFare = params.depart || {}
    state.returnFare = params.return || {}
  },

  SET_HOLIDAY(state, params) {
    state.holiday = params
  },

  RESET_HOLIDAY(state) {
    state.holiday = []
  }
}

export const actions = {
  async initHolidayCalendar(
    { state, commit },
    { country = 'ID', lang = 'en', vm = $nuxt }
  ) {
    if (!isEmpty(state.holiday)) return

    try {
      const {
        status_code,
        error,
        message,
        result
      } = await vm.$api.getHolidayList(country, lang)

      if (status_code === 200 && !error && !isEmpty(result))
        commit('SET_HOLIDAY', result)
      else throw message
    } catch {}
  },

  async updateFareCalendar({ commit }, params) {
    const defaultParams = {
      depAirport: '',
      arrAirport: '',
      currency: $nuxt.$store.state.config.currency,
      isRoundTrip: false,
      isAgent: $nuxt.$auth.user ? $nuxt.$auth.user.type === 'AGENT' : false
    }

    if (!params?.depAirport || !params?.arrAirport) return

    try {
      const {
        status_code,
        error,
        message,
        result
      } = await $nuxt.$api.getFareCalendar({ ...defaultParams, ...params })

      if (status_code === 200 && !error && !isEmpty(result))
        commit('SET_FARE', result)
      else throw message
    } catch (e) {}
  }
}

export const getters = {
  getHoliday: state => {
    return state.holiday.reduce((holiday, { date, name }) => {
      holiday[date] = name
      return holiday
    }, {})
  },

  getFares: state => (type = 'depart') => {
    const fares = type === 'return' ? state.returnFare : state.departFare
    const lowestFares = Object.entries(fares).reduce(
      (groups, [date, price]) => {
        const key = date.substring(0, 7)

        groups[key] = Math.min(groups[key] || price, price)

        return groups
      },
      {}
    )
    const isLowest = (date, price) => {
      return lowestFares[date.substring(0, 7)] === price
    }

    return Object.entries(fares).reduce((newFares, [date, price]) => {
      newFares[date] = {
        isLowest: isLowest(date, price),
        price
      }

      return newFares
    }, {})
  }
}
