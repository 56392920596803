import { isEmpty, groupBy } from 'lodash'
import { apiInstance } from '@/plugins/api'
import { filterMultiAirport } from '@/assets/js/globalFunction'

export const state = () => ({
  flightDepart: {},
  flightArrival: {},
  flightFrequent: [],
  hotel: {}
})

export const getters = {
  GET_FLIGHT_DEPART_AUTOCOMPLETE_DATA: state => {
    const flightSuggestion = state.flightDepart

    const groupedSuggestion = groupBy(
      flightSuggestion,
      suggest => suggest.country
    )
    return groupedSuggestion
  },
  GET_FLIGHT_DEPART_CATEGORIES: state => {
    return Object.keys(state.flightDepart)
  },
  GET_FLIGHT_ARRIVAL_AUTOCOMPLETE_DATA: state => {
    const flightSuggestion = isEmpty(state.flightArrival)
      ? state.flightDepart
      : state.flightArrival

    const groupedSuggestion = groupBy(
      flightSuggestion,
      suggest => suggest.country
    )

    return groupedSuggestion
  },
  GET_FLIGHT_ARRIVAL_CATEGORIES: state => {
    return Object.keys(
      isEmpty(state.flightArrival) ? state.flightDepart : state.flightArrival
    )
  }
}

export const mutations = {
  SET_FLIGHT_DEPART(state, result) {
    state.flightDepart = result
  },
  SET_FLIGHT_ARRIVAL(state, result) {
    state.flightArrival = result
  },
  ADD_FLIGHT_FREQUENT(state, airport) {
    if (!state.flightFrequent.find(item => item.code === airport.code)) {
      if (state.flightFrequent.length >= 9) state.flightFrequent.shift()
      state.flightFrequent.push(airport)
    }
  },
  SET_HOTEL(state, result) {
    state.hotel = result
  },
  RESET_FLIGHT_FREQUENT(state) {
    state.flightFrequent = []
  }
}

export const actions = {
  _normalizeFlightFrequentData({ state, commit }) {
    if (
      Array.isArray(state.flightFrequent) &&
      state.flightFrequent.some(flight => flight.hasOwnProperty('airportCode'))
    )
      commit('RESET_FLIGHT_FREQUENT')
  },
  async INIT_FLIGHT_DEPART({ rootState, commit }, isAirportOnly = false) {
    if (isEmpty(state.flightDepart)) {
      try {
        const responseSuggestion = await apiInstance.api.getFlightSuggestion(
          rootState.config.locale,
          rootState.config.country
        )
        const { status_code, result: suggestions } = responseSuggestion

        if (status_code === 200) {
          commit(
            'SET_FLIGHT_DEPART',
            isAirportOnly ? filterMultiAirport(suggestions) : suggestions
          )
        } else {
          throw responseSuggestion
        }
      } catch (err) {}
    }
  },
  async INIT_FLIGHT_ARRIVAL(
    { rootState, commit },
    code,
    isAirportOnly = false
  ) {
    try {
      const {
        status_code,
        result: suggestions
      } = await apiInstance.api.getFlightSuggestion(
        rootState.config.locale,
        rootState.config.country,
        code
      )
      if (status_code === 200) {
        commit(
          'SET_FLIGHT_ARRIVAL',
          isAirportOnly ? filterMultiAirport(suggestions) : suggestions
        )
      }
    } catch (err) {}
  }
}
